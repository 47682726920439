import React, { useMemo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import ClassicLayout from '../components/Layouts/ClassicLayout'

import { graphql } from 'gatsby'
import { gtagContactConversion } from '../utils/helpers/utils'
import {
  getPageData,
  getPrismicListDocument,
} from '../utils/helpers/transformers'
import {
  setEntry,
  setVisibleEntry,
  animateEntry,
} from '../utils/page-animations/careers'
import useAnimationState from '../utils/hooks/useAnimationState'

import ScrollTrigger from 'gsap/ScrollTrigger'

const Careers = ({ uri, pageContext, data }) => {
  const { routeName, language } = pageContext

  // INTN
  const { t } = useTranslation(['careers', 'common'])

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  // REF
  const $hasRunAnimation = useRef(false)

  // DATA
  const pageData = useMemo(() => getPageData(data), [data])
  const offers = useMemo(
    () => getPrismicListDocument(pageData, 'offers', 'offer'),
    [pageData]
  )

  console.log(pageData)

  // SEO
  const seoData = useMemo(() => {
    return {
      title: pageData.seo_title ? pageData.seo_title : t('careers:seo:title'),
      description: pageData.seo_description
        ? pageData.seo_description
        : t('careers:seo:description'),
      image: pageData.seo_image.url ? pageData.seo_image.url : null,
    }
  }, [pageData, t])

  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setVisibleEntry()
      return
    }
  }, [animationState.isReady, animationState.canStart])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    const tls = animateEntry()
    $hasRunAnimation.current = true

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])

  return (
    <ClassicLayout
      seo={seoData}
      language={language}
      name={routeName}
      uri={uri}
      internationalRoute={pageContext.internationalRoute}
    >
      <section className="hero">
        <div className="container">
          <div className="row f--desktop">
            <div className="col col-md-10 col-lg-offset-1 col-lg-4 order-2 mt-md-8">
              <h1
                className="tpl-careers__title hide-desktop ft-default ft-f-default ft-400 mb-5"
                aria-label={t('careers:title')}
              >
                {t('careers:title')}
              </h1>
              <h2 className="tpl-careers__subtitle  h5 c-white ft-f-default ft-400 mb-3 mb-lg-4">
                {t('careers:subtitle')}
              </h2>
              <p className="tpl-careers__desc mb-3">
                {t('careers:paragraph_1')}
              </p>
              <p className="tpl-careers__desc ft-secondary mb-4">
                {t('careers:paragraph_2')}
              </p>
              <div className="tpl-careers__button mosaic mosaic--2 mosaic-md-3 mosaic-lg-2">
                <a
                  href={`${t('careers:lever')}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={gtagContactConversion}
                  className="btn btn--center btn--full btn--dark btn--small border-w-4 ft-truncat"
                >
                  {t('careers:cta')}
                </a>
              </div>
            </div>
            <div className="tpl-careers__right col col-lg-8 mt-7 mt-lg-0">
              <h1 className="hide-mobile ft-default ft-f-default ft-400 mb-2">
                {t('careers:title')}
              </h1>
              <ul className="list-jobs">
                {offers.map((offer, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={offer.data.lever_link?.url}
                        target="_BLANK"
                        rel="noreferrer"
                        title={offer.data.title.text}
                      >
                        <h2 className="c-white ft-f-default ft-400 ft-truncat mb-1 mb-md-0">
                          {offer.data.title.text}
                        </h2>
                        <p className="ft-default-m-small c-alpha-5 mb-0 ft-truncat">
                          {offer.data.tags.text}
                        </p>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </ClassicLayout>
  )
}

export default Careers

export const query = graphql`
  query ($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allPrismicCareersPage(filter: { lang: { eq: $contentLang } }) {
      ...prismicCareersPage
    }
  }
`
